import React from 'react';
import whatsapp from '../assets/img/whatsapp.png'
import './WhatsAppButton.css';

const WhatsAppButton = () => {
    const phoneNumber = "+56959231703"; // Reemplaza con tu número de WhatsApp
    const message = "Hola, estoy interesado en saber más sobre sus servicios. \n #Mensaje de Prueba - Servicios Universal#"; // Mensaje predefinido
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

    return (
        <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="whatsapp-button">
            <img src={whatsapp} alt="WhatsApp" />
        </a>
    );
}

export default WhatsAppButton;
