import React, { useState, useEffect } from "react";
import logo from "../assets/img/logos/FINAL.png";

function Navbar() {
  const [navbarDark, setNavbarDark] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 400) {
      setNavbarDark(true);
    } else {
      setNavbarDark(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg fixed-top ${
        navbarDark ? "navbar-dark bg-dark" : "navbar-light"
      }`}
      id="mainNav"
    >
      <div className="container">
        <a className="navbar-brand" href="#page-top">
          <img src={logo} alt="..." className="navbar-logo" />
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="fas fa-bars ms-1"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav text-uppercase ms-auto py-4 py-lg-0">
            <li className="nav-item">
              <a className="nav-link" href="#services">
                Servicios
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#portfolio">
                Productos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                Quienes Somos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#team">
                Team
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contact">
                Contacto
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
