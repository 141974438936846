import React from 'react';
import styled from 'styled-components';
import logo from "../assets/img/logos/FINAL.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Logo = styled.img`
  max-width: 150px;
  margin-bottom: 20px;
`;

const Header = styled.h1`
  font-size: 2rem;
  margin-bottom: 10px;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 20px;
`;

const Footer = styled.footer`
  margin-top: 20px;
  font-size: 0.9rem;
  color: #888;
`;

const Maintenance = () => {
  return (
    <Container>
      <Logo src={logo} alt="Logo del Sitio" />
      <Header>Sitio en Mantenimiento</Header>
      <Subtitle>Estamos trabajando en mejorar el sitio. ¡Volvemos pronto!</Subtitle>
      <Footer>&copy; 2024 Servicios Universal</Footer>
    </Container>
  );
};

export default Maintenance;