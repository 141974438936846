import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Masthead from './components/Masthead';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import About from './components/About';
import Team from './components/Team';
import Clients from './components/Clients';
import Contact from './components/Contact';
import Footer from './components/Footer';
import WhatsAppButton from './components/WhatsAppButton';
import Maintenance from './components/Maintenance';
import './App.css';

function App() {
  const [isMaintenance, setIsMaintenance] = useState(true);

  return (
    <div>
      {isMaintenance ? (
        <Maintenance />
      ) : (
        <>
          <Navbar />
          <Masthead />
          <Services />
          <Portfolio />
          <About />
          <Team />
          <Clients />
          <Contact />
          <Footer />
          <WhatsAppButton />
        </>
      )}
    </div>
  );
}

export default App;

