import React from "react";
import portafolio1 from "../assets/img/Imagen1.jpg";
import portafolio2 from "../assets/img/Imagen2.jpg";
import portafolio3 from "../assets/img/Imagen3.jpg";
import portafolio4 from "../assets/img/Imagen4.jpg";
import portafolio5 from "../assets/img/Imagen5.jpg";
import portafolio6 from "../assets/img/Imagen6.png";

function Portfolio() {
  return (
    <section className="page-section bg-light" id="portfolio">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Portfolio</h2>
          <h3 className="section-subheading text-muted">
            Lorem ipsum dolor sit amet consectetur.
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a
                className="portfolio-link"
                data-bs-toggle="modal"
                href="#portfolioModal1"
              >
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fas fa-plus fa-3x"></i>
                  </div>
                </div>
                <img className="img-fluid portfolio-image" src={portafolio1} alt="..." />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Fundicion</div>
                <div className="portfolio-caption-subheading text-muted">
                  Illustration
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a
                className="portfolio-link"
                data-bs-toggle="modal"
                href="#portfolioModal2"
              >
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fas fa-plus fa-3x"></i>
                  </div>
                </div>
                <img className="img-fluid portfolio-image" src={portafolio2} alt="..." />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Jaulas Metalicas</div>
                <div className="portfolio-caption-subheading text-muted">
                  Illustration
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a
                className="portfolio-link"
                data-bs-toggle="modal"
                href="#portfolioModal3"
              >
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fas fa-plus fa-3x"></i>
                  </div>
                </div>
                <img className="img-fluid portfolio-image" src={portafolio3} alt="..." />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Andamios</div>
                <div className="portfolio-caption-subheading text-muted">
                  Illustration
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a
                className="portfolio-link"
                data-bs-toggle="modal"
                href="#portfolioModal4"
              >
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fas fa-plus fa-3x"></i>
                  </div>
                </div>
                <img className="img-fluid portfolio-image" src={portafolio4} alt="..." />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Estructuras Metalicas</div>
                <div className="portfolio-caption-subheading text-muted">
                  Illustration
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a
                className="portfolio-link"
                data-bs-toggle="modal"
                href="#portfolioModal5"
              >
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fas fa-plus fa-3x"></i>
                  </div>
                </div>
                <img className="img-fluid portfolio-image" src={portafolio5} alt="..." />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Threads</div>
                <div className="portfolio-caption-subheading text-muted">
                  Illustration
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 mb-4">
            <div className="portfolio-item">
              <a
                className="portfolio-link"
                data-bs-toggle="modal"
                href="#portfolioModal6"
              >
                <div className="portfolio-hover">
                  <div className="portfolio-hover-content">
                    <i className="fas fa-plus fa-3x"></i>
                  </div>
                </div>
                <img className="img-fluid portfolio-image" src={portafolio6} alt="..." />
              </a>
              <div className="portfolio-caption">
                <div className="portfolio-caption-heading">Threads</div>
                <div className="portfolio-caption-subheading text-muted">
                  Illustration
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="portfolioModal1"
        tabIndex="-1"
        aria-labelledby="portfolioModal1Label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h2 className="text-uppercase">Threads</h2>
              <p className="item-intro text-muted">
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <img
                className="img-fluid d-block mx-auto"
                src={portafolio1}
                alt="..."
              />
              <p>
                Lorem Ipsum es simplemente el texto de relleno de las imprentas
                y archivos de texto. Lorem Ipsum ha sido el texto de relleno
                estándar de las industrias desde el año 1500, cuando un impresor
                (N. del T. persona que se dedica a la imprenta) desconocido usó
                una galería de textos y los mezcló de tal manera que logró hacer
                un libro de textos especimen. No sólo sobrevivió 500 años, sino
                que tambien ingresó como texto de relleno en documentos
                electrónicos, quedando esencialmente igual al original. Fue
                popularizado en los 60s con la creación de las hojas "Letraset",
                las cuales contenian pasajes de Lorem Ipsum, y más recientemente
                con software de autoedición, como por ejemplo Aldus PageMaker,
                el cual incluye versiones de Lorem Ipsum.
              </p>
              <ul className="list-inline">
                <li>
                  <strong>Client:</strong> Threads
                </li>
                <li>
                  <strong>Category:</strong> Illustration
                </li>
              </ul>
              <button
                className="btn btn-primary btn-xl text-uppercase"
                data-bs-dismiss="modal"
                type="button"
              >
                <i className="fas fa-xmark me-1"></i>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="portfolioModal2"
        tabIndex="-1"
        aria-labelledby="portfolioModal2Label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h2 className="text-uppercase">Threads</h2>
              <p className="item-intro text-muted">
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <img
                className="img-fluid d-block mx-auto"
                src={portafolio2}
                alt="..."
              />
              <p>Use this area to describe your project...</p>
              <ul className="list-inline">
                <li>
                  <strong>Client:</strong> Threads
                </li>
                <li>
                  <strong>Category:</strong> Illustration
                </li>
              </ul>
              <button
                className="btn btn-primary btn-xl text-uppercase"
                data-bs-dismiss="modal"
                type="button"
              >
                <i className="fas fa-xmark me-1"></i>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="portfolioModal3"
        tabIndex="-1"
        aria-labelledby="portfolioModal3Label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h2 className="text-uppercase">Threads</h2>
              <p className="item-intro text-muted">
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <img
                className="img-fluid d-block mx-auto"
                src={portafolio3}
                alt="..."
              />
              <p>Use this area to describe your project...</p>
              <ul className="list-inline">
                <li>
                  <strong>Client:</strong> Threads
                </li>
                <li>
                  <strong>Category:</strong> Illustration
                </li>
              </ul>
              <button
                className="btn btn-primary btn-xl text-uppercase"
                data-bs-dismiss="modal"
                type="button"
              >
                <i className="fas fa-xmark me-1"></i>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="portfolioModal4"
        tabIndex="-1"
        aria-labelledby="portfolioModal4Label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h2 className="text-uppercase">Threads</h2>
              <p className="item-intro text-muted">
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <img
                className="img-fluid d-block mx-auto"
                src={portafolio4}
                alt="..."
              />
              <p>Use this area to describe your project...</p>
              <ul className="list-inline">
                <li>
                  <strong>Client:</strong> Threads
                </li>
                <li>
                  <strong>Category:</strong> Illustration
                </li>
              </ul>
              <button
                className="btn btn-primary btn-xl text-uppercase"
                data-bs-dismiss="modal"
                type="button"
              >
                <i className="fas fa-xmark me-1"></i>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="portfolioModal5"
        tabIndex="-1"
        aria-labelledby="portfolioModal5Label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h2 className="text-uppercase">Threads</h2>
              <p className="item-intro text-muted">
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <img
                className="img-fluid d-block mx-auto"
                src={portafolio5}
                alt="..."
              />
              <p>Use this area to describe your project...</p>
              <ul className="list-inline">
                <li>
                  <strong>Client:</strong> Threads
                </li>
                <li>
                  <strong>Category:</strong> Illustration
                </li>
              </ul>
              <button
                className="btn btn-primary btn-xl text-uppercase"
                data-bs-dismiss="modal"
                type="button"
              >
                <i className="fas fa-xmark me-1"></i>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="portfolioModal6"
        tabIndex="-1"
        aria-labelledby="portfolioModal6Label"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-body">
              <h2 className="text-uppercase">Threads</h2>
              <p className="item-intro text-muted">
                Lorem ipsum dolor sit amet consectetur.
              </p>
              <img
                className="img-fluid d-block mx-auto"
                src={portafolio6}
                alt="..."
              />
              <p>Use this area to describe your project...</p>
              <ul className="list-inline">
                <li>
                  <strong>Client:</strong> Threads
                </li>
                <li>
                  <strong>Category:</strong> Illustration
                </li>
              </ul>
              <button
                className="btn btn-primary btn-xl text-uppercase"
                data-bs-dismiss="modal"
                type="button"
              >
                <i className="fas fa-xmark me-1"></i>
                Close Project
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Repite el código del modal para cada imagen del portafolio */}
    </section>
  );
}

export default Portfolio;
