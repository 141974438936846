import React from 'react';
import client1 from '../assets/img/logos/microsoft.svg';
import client2 from '../assets/img/logos/google.svg';
import client3 from '../assets/img/logos/facebook.svg';
import client4 from '../assets/img/logos/ibm.svg';

function Clients() {
  return (
    <div className="py-5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-3 col-sm-6 my-3">
            <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src={client1} alt="..." aria-label="Microsoft Logo" /></a>
          </div>
          <div className="col-md-3 col-sm-6 my-3">
            <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src={client2} alt="..." aria-label="Google Logo" /></a>
          </div>
          <div className="col-md-3 col-sm-6 my-3">
            <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src={client3} alt="..." aria-label="Facebook Logo" /></a>
          </div>
          <div className="col-md-3 col-sm-6 my-3">
            <a href="#!"><img className="img-fluid img-brand d-block mx-auto" src={client4} alt="..." aria-label="IBM Logo" /></a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
