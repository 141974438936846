import React from 'react';

function Masthead() {
  return (
    <header className="masthead">
      <div className="container">
        <div className="masthead-subheading">Bienvenido a Servicios Universal!</div>
        <div className="masthead-heading text-uppercase">Empresa lider en servicios mineros</div>
        <a className="btn btn-primary btn-xl text-uppercase" href="#services">Servicios</a>
      </div>
    </header>
  );
}

export default Masthead;
